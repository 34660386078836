<template>
    <div id="supervisor-item-sheet">


        <b-modal ref="modal" ok-only ok-title="Modifier" centered size="lg">
            <ag-grid :agGrid="agGrid" :showHeader="false" />
        </b-modal>



        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card>

                <b-row>
                    <b-col cols="8">
                        <h4>Fiche Article</h4>
                    </b-col>
                    <b-col cols="4" class="text-right" v-if="$route.params.code != 'new-item'">

                        <b-dropdown variant="link" no-caret toggle-class="p-0" right>

                            <template #button-content>
                                <b-button variant="primary" class="btn-icon">
                                    <feather-icon icon="SettingsIcon" class="" />
                                </b-button>
                            </template>

                            <b-dropdown-item @click="getMapping" v-b-modal.modal-lg>
                                <feather-icon icon="GitMergeIcon" size="16" />
                                <span class="align-middle ml-50">Mappage</span>
                            </b-dropdown-item>

                        </b-dropdown>
                    </b-col>
                </b-row>

                <hr>

                <b-card-body>

                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group label="Code">
                                <b-form-input v-model="item.code" disabled />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="8">
                            <b-form-group label="Description">
                                <b-form-input v-model="item.description" placeholder="Description" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group label="Famille">
                                <v-select v-model="familySelected" label="description" :options="familyOptions"
                                    placeholder="Family" />
                            </b-form-group>
                        </b-col>


                        <b-col cols="12" md="4">
                            <b-form-group label="Sous-Famille">
                                <v-select v-model="subFamilySelected" label="description" :options="subFamilyOptions"
                                    placeholder="SUB-Family" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group label="Fournisseur">
                                <b-form-input v-model="item.vendorDescription" placeholder="Vendor" disabled />
                            </b-form-group>
                        </b-col>


                        <b-col cols="12" md="3">
                            <b-form-group label="BarCode">
                                <b-form-input v-model="item.barCode" placeholder="BarCode" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group label="Cartonnage">
                                <b-form-input v-model="item.packaging" placeholder="Packaging" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="3">
                            <b-form-group label="Prix H.T">
                                <b-form-input v-model="item.priceHT" placeholder="Unit Price H.T" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="3">
                            <b-form-group label="Prix T.T.C">
                                <b-form-input v-model="item.priceTTC" placeholder="Unit Price T.T.C" />
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <hr>

                    <quill-editor v-model='item.indications'></quill-editor>

                    <hr>

                    <div>
                        <b-row :key="imagesKey">
                            <b-col>
                                <div style="text-align: center;">
                                    <img v-if="srcImg1 == ''" :src="require('@/assets/images/default.jpg')"
                                        style="height: 166px; width: 233px" />
                                    <img v-else :src="srcImg1" style="height: 166px; width: 233px" />
                                </div>
                                <div style="text-align: center;">
                                    <label for="file-upload1" class="custom-file-upload" style="width: 233px;">
                                        <feather-icon icon="UploadIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" /> Importer
                                    </label>
                                    <input :disabled="item.disabled == true" ref="imageFile1" id="file-upload1" type="file"
                                        @change="preview(1)" />
                                </div>
                                <div style="text-align: center;">
                                    <label class="custom-file-upload" style="width: 233px;" @click="deleteImage(1)">
                                        <feather-icon icon="DeleteIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" />
                                        Supprimer
                                    </label>
                                </div>
                            </b-col>
                            <b-col>
                                <div style="text-align: center;">
                                    <img v-if="srcImg2 == ''" :src="require('@/assets/images/default.jpg')"
                                        style="height: 166px; width: 233px" />
                                    <img v-else :src="srcImg2" style="height: 166px; width: 233px" />
                                </div>
                                <div style="text-align: center;">
                                    <label for="file-upload2" class="custom-file-upload" style="width: 233px;">
                                        <feather-icon icon="UploadIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" /> Importer
                                    </label>
                                    <input :disabled="item.disabled == true" ref="imageFile2" id="file-upload2" type="file"
                                        @change="preview(2)" />
                                </div>
                                <div style="text-align: center;">
                                    <label class="custom-file-upload" style="width: 233px;" @click="deleteImage(2)">
                                        <feather-icon icon="DeleteIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" />
                                        Supprimer
                                    </label>
                                </div>
                            </b-col>
                            <b-col>
                                <div style="text-align: center;">
                                    <img v-if="srcImg3 == ''" :src="require('@/assets/images/default.jpg')"
                                        style="height: 166px; width: 233px" />
                                    <img v-else :src="srcImg3" style="height: 166px; width: 233px" />
                                </div>
                                <div style="text-align: center;">
                                    <label for="file-upload3" class="custom-file-upload" style="width: 233px;">
                                        <feather-icon icon="UploadIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" /> Importer
                                    </label>
                                    <input :disabled="item.disabled == true" ref="imageFile3" id="file-upload3" type="file"
                                        @change="preview(3)" />
                                </div>
                                <div style="text-align: center;">
                                    <label class="custom-file-upload" style="width: 233px;" @click="deleteImage(3)">
                                        <feather-icon icon="DeleteIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" />
                                        Supprimer
                                    </label>
                                </div>
                            </b-col>
                            <b-col>
                                <div style="text-align: center;">
                                    <img v-if="srcImg4 == ''" :src="require('@/assets/images/default.jpg')"
                                        style="height: 166px; width: 233px" />
                                    <img v-else :src="srcImg4" style="height: 166px; width: 233px" />
                                </div>
                                <div style="text-align: center;">
                                    <label for="file-upload4" class="custom-file-upload" style="width: 233px;">
                                        <feather-icon icon="UploadIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" /> Importer
                                    </label>
                                    <input :disabled="item.disabled == true" ref="imageFile4" id="file-upload4" type="file"
                                        @change="preview(4)" />
                                </div>
                                <div style="text-align: center;">
                                    <label class="custom-file-upload" style="width: 233px;" @click="deleteImage(4)">
                                        <feather-icon icon="DeleteIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" />
                                        Supprimer
                                    </label>
                                </div>
                            </b-col>
                            <b-col>
                                <div style="text-align: center;">
                                    <img v-if="srcImg5 == ''" :src="require('@/assets/images/default.jpg')"
                                        style="height: 166px; width: 233px" />
                                    <img v-else :src="srcImg5" style="height: 166px; width: 233px" />
                                </div>
                                <div style="text-align: center;">
                                    <label for="file-upload5" class="custom-file-upload" style="width: 233px;">
                                        <feather-icon icon="UploadIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" /> Importer
                                    </label>
                                    <input :disabled="item.disabled == true" ref="imageFile5" id="file-upload5" type="file"
                                        @change="preview(5)" />
                                </div>
                                <div style="text-align: center;">
                                    <label class="custom-file-upload" style="width: 233px;" @click="deleteImage(5)">
                                        <feather-icon icon="DeleteIcon" svgClasses="h-4 w-4 mr-2 cursor-pointer" />
                                        Supprimer
                                    </label>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <hr>

                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="relief-warning" class="mr-4" @click="resetData">
                                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                                <span class="align-middle">Reset</span>
                            </b-button>
                            <b-button variant="relief-success" @click="saveData">
                                <feather-icon icon="PocketIcon" class="mr-50" />
                                <span class="align-middle">Save</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import AgGrid from "@/views/components/ag-grid/AgGrid.vue";

import {
    quillEditor
} from 'vue-quill-editor';


export default {
    components: {
        vSelect,
        quillEditor,
        AgGrid
    },
    data() {
        return {
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Grossiste",
                        field: "company",
                    },
                    {
                        headerName: "N° Article",
                        field: "itemNo",
                    },
                ],
                rows: []
            },
            fileName: '',
            imagesKey: 0,
            srcImg1: "",
            srcImg2: "",
            srcImg3: "",
            srcImg4: "",
            srcImg5: "",
            img1: '',
            img2: '',
            img3: '',
            img4: '',
            img5: '',
            idImg1: 0,
            idImg2: 0,
            idImg3: 0,
            idImg4: 0,
            idImg5: 0,
            currentIndex: 0,
            editorOption: {
                theme: 'bubble',
                readOnly: false
            },
            showLoading: false,
            item: {},
            familyOptions: [],
            familySelected: {},
            allSubFamilyOptions: [],
            subFamilyOptions: [],
            subFamilySelected: {},
        }
    },
    watch: {
        familySelected(value) {
            this.subFamilyOptions = this.allSubFamilyOptions.filter(item => item.familyCode === value.code);
        }
    },
    async created() {
        this.showLoading = true;
        if (this.$route.params.code == "new-item") {
            let familyOptions = await this.$http.get("_item/family").catch(() => this.showLoading = false);
            this.familyOptions = familyOptions.data;
            let subFamilyOptions = await this.$http.get("_item/subFamily").catch(() => this.showLoading = false);
            this.allSubFamilyOptions = subFamilyOptions.data;
            this.item.disabled = true;
        } else {
            let response = await this.$http.get("_item/findById", { params: { code: this.$route.params.code } }).catch(() => this.showLoading = false);
            this.item = response.data;
            let familyOptions = await this.$http.get("_item/family").catch(() => this.showLoading = false);
            this.familyOptions = familyOptions.data;
            this.familySelected = this.familyOptions.find(item => item.code == this.item.familyCode);
            let subFamilyOptions = await this.$http.get("_item/subFamily").catch(() => this.showLoading = false);
            this.allSubFamilyOptions = subFamilyOptions.data;
            this.subFamilySelected = this.allSubFamilyOptions.find(item => item.code == this.item.subFamilyCode);
            let images = await this.$http.get("file/findAll/" + this.$route.params.code).catch(() => this.showLoading = false);
            for (let index = 0; index < images.data.length; index++) {
                switch (index) {
                    case 0:
                        this.srcImg1 = this.$serverpath + images.data[index].path;
                        this.idImg1 = images.data[index].id;
                        break;
                    case 1:
                        this.srcImg2 = this.$serverpath + images.data[index].path;
                        this.idImg2 = images.data[index].id;
                        break;
                    case 2:
                        this.srcImg3 = this.$serverpath + images.data[index].path;
                        this.idImg3 = images.data[index].id;
                        break;
                    case 3:
                        this.srcImg4 = this.$serverpath + images.data[index].path;
                        this.idImg4 = images.data[index].id;
                        break;
                    case 4:
                        this.srcImg5 = this.$serverpath + images.data[index].path;
                        this.idImg5 = images.data[index].id;
                        break;
                }
            }
        }
        this.showLoading = false;
    },
    methods: {
        async getMapping() {
            this.showLoading = true;
            let resp = await this.$http.get("_item/mappingItems/" + this.item.code);
            setTimeout(() => {
                this.agGrid.rows = resp.data;
            }, 300);
            this.$refs["modal"].show();
            this.showLoading = false;
        },
        resetData() {
            if (this.$route.params.code == 0) {
                this.item = {};
                this.familySelected = {};
                this.subFamilySelected = {};
            }
        },
        async saveData() {
            this.showLoading = true;
            this.item.familyCode = this.familySelected.code;
            this.item.familyDescription = this.familySelected.description;
            this.item.subFamilyCode = this.subFamilySelected.code;
            this.item.subFamilyDescription = this.subFamilySelected.description;

            let response = await this.$http.post("_item", this.item).catch(() => this.showLoading = false);
            if (response.status == 200)
                if (this.item.code == "" || this.item.code == undefined) {
                    this.item.code = response.data.code;
                    this.item.vendorDescription = response.data.vendorDescription;
                    this.showLoading = false;
                    this.item.disabled = false;
                }
                else
                    this.$router.push({ name: 'supervisor-item-list' });
        },
        preview(index) {
            this.showLoading = true;
            let files = '';
            let currentFileUpload = '';
            let self = this;
            switch (index) {
                case 1:
                    files = self.$refs.imageFile1.files;
                    if (files.length === 0) {
                        return;
                    }
                    currentFileUpload = files.item(0);
                    self.img1 = currentFileUpload;
                    self.file = currentFileUpload;
                    break;

                case 2:
                    files = self.$refs.imageFile2.files;
                    if (files.length === 0) {
                        return;
                    }
                    currentFileUpload = files.item(0);
                    self.img2 = currentFileUpload;
                    self.file = currentFileUpload;
                    break;
                case 3:
                    files = self.$refs.imageFile3.files;
                    if (files.length === 0) {
                        return;
                    }
                    currentFileUpload = files.item(0);
                    self.img3 = currentFileUpload;
                    self.file = currentFileUpload;
                    break;
                case 4:
                    files = self.$refs.imageFile4.files;
                    if (files.length === 0) {
                        return;
                    }
                    currentFileUpload = files.item(0);
                    self.img4 = currentFileUpload;
                    self.file = currentFileUpload;
                    break;
                case 5:
                    files = self.$refs.imageFile5.files;
                    if (files.length === 0) {
                        return;
                    }
                    currentFileUpload = files.item(0);
                    self.img5 = currentFileUpload;
                    self.file = currentFileUpload;
                    break;
            }
            self.currentIndex = index;
            this.fileName = currentFileUpload.name;
            const fileReader = new FileReader();
            fileReader.onload = self._handleReaderLoaded.bind(self);
            fileReader.readAsBinaryString(currentFileUpload);
        },
        async _handleReaderLoaded(readerEvt) {
            const binaryString = readerEvt.target.result;
            let filestring = btoa(binaryString);
            let imgURL = "data:image/jpeg;base64," + filestring;
            try {
                await this.sendApiRequest(filestring);
                // this.$swal.fire({
                //     position: "absolute",
                //     icon: "success",
                //     title: "Image modifiée avec succès",
                //     showConfirmButton: true,
                //     timer: 5000
                // });
            } catch (error) {
                this.showLoading = false;
            }
            switch (this.currentIndex) {
                case 1:
                    this.srcImg1 = imgURL;
                    break;
                case 2:
                    this.srcImg2 = imgURL;
                    break;
                case 3:
                    this.srcImg3 = imgURL;
                    break;
                case 4:
                    this.srcImg4 = imgURL;
                    break;
                case 5:
                    this.srcImg5 = imgURL;
                    break;
            }
            this.imagesKey += 1;
        },
        async sendApiRequest(image) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("idObject", this.$route.params.code);
                formData.append("name", this.fileName);
                formData.append("extension", this.getFileExtension(this.fileName));
                formData.append("content", image);
                this.$http.post("file", formData).catch((error) => {
                    reject(error);
                    this.showLoading = false;
                }).then((resp) => {
                    switch (this.currentIndex) {
                        case 1:
                            this.idImg1 = resp.data.id;
                            break;
                        case 2:
                            this.idImg2 = resp.data.id;
                            break;
                        case 3:
                            this.idImg3 = resp.data.id;
                            break;
                        case 4:
                            this.idImg4 = resp.data.id;
                            break;
                        case 5:
                            this.idImg5 = resp.data.id;
                            break;
                    }
                    this.showLoading = false;
                });
                resolve();
            });
        },
        getFileExtension(filename) {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        },
        async deleteImage(index) {
            this.showLoading = true;
            switch (index) {
                case 1:
                    this.img1 = "";
                    this.srcImg1 = "";
                    if (this.idImg1 != 0) {
                        await this.$http.get("file/delete/" + this.idImg1).catch(() => this.showLoading = false);
                        this.idImg1 = 0;
                        this.showLoading = false;
                    } else {
                        this.showLoading = false;
                    }
                    break;
                case 2:
                    this.img2 = "";
                    this.srcImg2 = "";
                    if (this.idImg2 != 0) {
                        await this.$http.get("file/delete/" + this.idImg2).catch(() => this.showLoading = false);
                        this.idImg2 = 0;
                        this.showLoading = false;
                    } else {
                        this.showLoading = false;
                    }
                    break;
                case 3:
                    this.img3 = "";
                    this.srcImg3 = "";
                    if (this.idImg3 != 0) {
                        await this.$http.get("file/delete/" + this.idImg3).catch(() => this.showLoading = false);
                        this.idImg3 = 0;
                        this.showLoading = false;
                    } else {
                        this.showLoading = false;
                    }
                    break;
                case 4:
                    this.img4 = "";
                    this.srcImg4 = "";
                    if (this.idImg4 != 0) {
                        await this.$http.get("file/delete/" + this.idImg4).catch(() => this.showLoading = false);
                        this.idImg4 = 0;
                        this.showLoading = false;
                    } else {
                        this.showLoading = false;
                    }
                    break;
                case 5:
                    this.img5 = "";
                    this.srcImg5 = "";
                    if (this.idImg5 != 0) {
                        await this.$http.get("file/delete/" + this.idImg5).catch(() => this.showLoading = false);
                        this.idImg5 = 0;
                        this.showLoading = false;
                    } else {
                        this.showLoading = false;
                    }
                    break;
            }
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.vs-input--label {
    font-weight: bold;
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>